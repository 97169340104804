<template>
  <div>
    <div>
      <div style="height: inherit">
        <div class="card">
      

  <b-modal id="modal-1" size="xl" title="PNLC-MBDG-EXE-SCSB-BIM-OVR-00_202201">
    <p class=""> <iframe src="https://event-player.vertikaliti.com/plc.html" scrolling="no" style="width: 100%;height: 800px;" frameborder="0"></iframe ></p>
  </b-modal> 

   <table class="table table-bordered table-hover">
                    <thead class="thead-dark-custom">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Actions</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                       
                        <td>PNLC-MBDG-EXE-SCSB-BIM-OVR-00_202201</td>
                        
                        <td>
                       
                         <b-button v-b-modal.modal-1>View</b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal-footer{
  display: none !important;
}
.card-image {
  position: absolute;
  top: 20px;
  left: 20px;
}
.card-info {
  margin-left: 60px;
}
.card {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
</style>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormRadioGroup,
    axios,

    // vSelect,
  },
  data() {
    return {
      documentList: [],
      pdfList: [],
      pdfList_details: [],
      showinfo_details_pdf: false,
      selectedUser: "",
      selectedUser_pdf: "",
    };
  },
  mounted() {
   
  },
  methods: {
   
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
